/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { copyText, formatNumber, getAddress, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import TransactionService from "services/transactions";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoadingSaveDetail, statuses } =
    props;

  const emptyData = {
    user: null,
    user_id: null,
    type: "",
    amount: 0,
    description: "",
    status: "pending",
    hash: "",
    to: "",
    amountReceived: 0,
    full_name : " "
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: (action) => {
      submit(action);
    },
  }));

  useEffect(() => {
    if (data) {
      const new_data = {
        ...data,
        full_name : data.user.full_name
      }
      setDetails(new_data)
    }

  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async (action) => {
    try {
      setLoadingSaveDetail(true);
      if (data) {
        await TransactionService.updateTransaction({
          body: {
            ...details,
            transactionId: data._id,
          },
        });
      }
      setLoadingSaveDetail(false);
      showToast(toast, "success", "Save success!");
      onCancel();
      reload();
    } catch (error) {
      setLoadingSaveDetail(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleCopy = (value) => {
    copyText(value);
    showToast(toast, "success", "Copied!");
  };

  return (
    <div className="grid">
      <div className="field col-12  md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={statuses}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="name"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Full name</label>
        <InputText
          id="full_name"
          value={`${details.full_name}`}
          onChange={(e) => onInputChange(e, "full_name")}
          // readOnly
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText id="email" value={details.user?.email} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">To</label>
        <div onClick={(_) => handleCopy(details.to)} className="relative">
          <InputText id="email" value={getAddress(details.to)} readOnly />
          <span
            className="absolute top-0 right-0 mr-2 mt-2 bx bxs-copy cursor-pointer"></span>
        </div>
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Amount </label>
        <InputText value={`${formatNumber(details.amount)}`} readOnly />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Amount Received</label>
        <div onClick={(_) => handleCopy(details.amountReceived)} className="relative">
          <InputText
            value={`${formatNumber(details.amountReceived)}`}
            readOnly
          />
          <span
            className="absolute top-0 right-0 mr-2 mt-2 bx bxs-copy cursor-pointer"></span>
        </div>
      </div>
      <div className="field col-12 ">
        <label htmlFor="type">Type</label>
        <InputText id="type" value={details.type} readOnly />
      </div>
      <div className="field col-12">
        <label htmlFor="content">Description</label>
        <InputText
          id="content"
          value={`${details.description}`}
          onChange={(e) => onInputChange(e, "description")}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Hash</label>
        <InputText
          id="hash"
          value={details.hash}
          onChange={(e) => onInputChange(e, "hash")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
