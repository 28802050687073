export const IDO_INFO = [
  {
    key: "token",
    value: "Token",
    type: "text",
  },
  {
    key: "network",
    value: "Network",
    type: "text",
  },
  {
    key: "price",
    value: "IDO Token Price",
    type: "text",
  },
  {
    key: "pool",
    value: "Total IDO Pool size",
    type: "text",
  },
  {
    key: "type",
    value: "IDO type",
    type: "text",
  },
  {
    key: "vesting",
    value: "Vesting",
    type: "text",
  },
];

export const KEEP_TIME = [
  {
    key: "ido_open",
    value: "IBO open",
    type: "datetime-local",
  },
  {
    key: "ido_close",
    value: "IBO close",
    type: "datetime-local",
  },
];

export const SOCIAL = [
  "Telegram",
  "Twitter",
  "Facebook",
  "Document",
  "Youtobe",
];

export const REWARD = {
  stocks: [],
  vouchers: [],
  codes: [],
  tickets: [],
};

export const REGIONS = [
  {
    code: 1,
    name: "Tây Bắc Bộ",
    provices: [
      "Điện Biên",
      "Hoà Bình",
      "Lai Châu",
      "Lào Cai",
      "Sơn La",
      "Yên Bái",
    ],
  },
  {
    code: 2,
    name: "Đông Bắc Bộ",
    provices: [
      "Bắc Giang",
      "Bắc Kạn",
      "Cao Bằng",
      "Hà Giang",
      "Lạng Sơn",
      "Phú Thọ",
      "Quảng Ninh",
      "Thái Nguyên",
      "Tuyên Quang",
    ],
  },
  {
    code: 3,
    name: "Đồng bằng sông Hồng",
    provices: [
      "Bắc Ninh",
      "Hà Nam",
      "Hà Nội",
      "Hải Dương",
      "Hải Phòng",
      "Hưng Yên",
      "Nam Định",
      "Ninh Bình",
      "Thái Bình",
      "Vĩnh Phúc",
    ],
  },
  {
    code: 4,
    name: "Bắc Trung Bộ",
    provices: [
      "Thanh Hóa",
      "Nghệ An",
      "Hà Tĩnh",
      "Quảng Bình",
      "Quảng Trị",
      "Thừa Thiên Huế",
    ],
  },
  {
    code: 5,
    name: "Nam Trung Bộ",
    provices: [
      "Đà Nẵng",
      "Quảng Nam",
      "Quảng Ngãi",
      "Bình Định",
      "Phú Yên",
      "Khánh Hòa",
      "Ninh Thuận",
      "Bình Thuận",
    ],
  },
  {
    code: 6,
    name: "Tây Nguyên",
    provices: ["Kon Tum", "Gia Lai", "Đắk Lắk", "Đắk Nông", "Lâm Đồng"],
  },
  {
    code: 7,
    name: "Đông Nam Bộ",
    provices: [
      "Bình Phước",
      "Bình Dương",
      "Đồng Nai",
      "Tây Ninh",
      "Vũng Tàu",
      "Thành phố Hồ Chí Minh",
    ],
  },
  {
    code: 8,
    name: "Tây Nam Bộ",
    provices: [
      "Long An",
      "Đồng Tháp",
      "Tiền Giang",
      "An Giang",
      "Bến Tre",
      "Vĩnh Long",
      "Trà Vinh",
      "Hậu Giang",
      "Kiên Giang",
      "Sóc Trăng",
      "Bạc Liêu",
      "Cà Mau",
      "Cần Thơ",
    ],
  },
];

export const LEVELS = [
  {
    name: "F1",
    value: "f1",
  },
  {
    name: "F2",
    value: "f2",
  },
  {
    name: "F3",
    value: "f3",
  },
  {
    name: "F4",
    value: "f4",
  },
  {
    name: "F5",
    value: "f5",
  },
];

export const INVEST_STYLE_STATUS = {
  pending: ["Pending"],
  success: ["Success"],
  waiting_user_confirm: ["Waiting user confirm"],
  waiting_admin_confirm: ["Waiting admin confirm"],
  waiting_payback: ["Waiting payback"],
  error: ["Error"],
  completed: ["Completed"],
  reinvested: ["Reinvested"],
};
