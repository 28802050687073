import { Button } from "primereact";
import { formatNumber } from "./common";

/* eslint-disable react/jsx-no-target-blank */
export const renderAmount = (amount) => {
  const _dau = amount >= 0 ? "+" : "-";
  const _class =
    amount >= 0
      ? "p-button-success p-button-text"
      : "p-button-danger p-button-text";
  return (
    <Button className={`font-bold ${_class} p-0`}>
      {_dau}
      {formatNumber(Math.abs(amount))}
    </Button>
  );
};

export const renderHash = (hash = "") => {
  const _hash = `${"https://bscscan.com/"}/tx/${hash}`;
  return (
    <a href={_hash} target={"_blank"}>
      {hash.slice(0, 5)}...{hash.slice(hash.length - 5, hash.length)}
    </a>
  );
};

export const renderPrv = (hash = "") => {
  return (
    <div>
      {hash.slice(0, 5)}...{hash.slice(hash.length - 5, hash.length)}
    </div>
  );
};
